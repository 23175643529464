<i18n locale="th" lang="yaml" >
page.title : "Ticket ที่เสร็จสิ้น"
page.description : "รายการของ Ticket ที่ได้ดำเนินการเสร็จสิ้นแล้ว"
</i18n>
<template>
	<div class="page">
		<div class="page-action-right">
			<a-button icon="left" @click="goBack">
				{{$t('common.back')}}
			</a-button>
		</div>
		<my-page-header
		:title="$t('page.title',{ name: drone.serialNo })"
		:description="$t('page.description',{ name: drone.serialNo })"/>

		<a-card :bordered="false">
			<TicketTableMode
				mode="recent"
				filter-name="droneInstanceId"
				:filter-value="drone.id"
				:hide-columns="['drone']"/>
		</a-card>
	</div>

</template>

<script>
import TicketTableMode from "@components/ticket/TicketTableMode.vue"
export default {
	components : {
		TicketTableMode,
	} ,
	props : {
		droneData : {
			type : Object,
			default : () => []
		}
	},
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		}
	} ,
	methods : {
		goBack() {
			this.$open({name: 'drone/view' , params: {serial_no : this.drone.serialNo}})
		}
	}
}
</script>
